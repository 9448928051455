<template>
    <div class="flex flex-col flex-1 text-base-content">
        <div v-if="isLoading" class="flex min-h-full min-w-full justify-center items-center rounded-md">
            <loader class="my-5" height="32px" width="32px" :loading="isLoading" />
            Loading
        </div>
        <FormulateForm 
            v-if="descriptionData && !isLoading"
            class="w-full flex flex-col gap-6"
            #default="{ hasErrors }"
        >
            <div class="flex gap-3 flex-col">
                <div class="flex gap-4 justify-between">
                    
                    <FormulateInput
                        v-model="descriptionData.package_name"
                        type="text"
                        validation="required:trim"
                        error-behavior="live"
                        label="Screening Package Name"
                        placeholder="Package Name"
                        class="flex-1"
                        :element-class="
                            (context, classes) => ['flex-1 min-w-full'].concat(classes)
                        "
                        :disabled="isViewMode"
                        @input="handleInput"
                            
                    />
                    <FormulateInput
                        v-model="descriptionData.entity_types"
                        @input="handleInput"
                        validation="required"
                        class="flex-1"
                        label="Entity Type"
                        placeholder="Select Entity Type"
                        type="multi-select"
                        variant="alt"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="computedEntityTypeOptions"
                        :loading="!computedEntityTypeOptions.length"
                        :disabled="isViewMode"
                        :config="{
                            ...ENTITY_TYPES_DROPDOWN_CONFIG,
                            limit: 4,
                        }"
                    />
                </div>

                <div class="flex flex-col gap-3 w-full">
                    <FormulateInput
                        v-model="descriptionData.package_description"
                        @input="handleInput"
                        class="w-full"
                        label="Description"
                        placeholder="Description"
                        type="textarea"
                        :element-class="
                            (context, classes) => ['flex-1 min-w-full'].concat(classes)
                        "
                        :disabled="isViewMode"
                        :rows="5"
                    />
                </div>
            </div>

            <UISection class="description__numbers">
                <template #title>
                    <span>Entity</span>
                </template>

                <div class="w-full scroll-bar">
                    <div class="description__numbers-row flex gap-4 justify-between">
                        <FormulateInput
                            v-model="descriptionData.minimum_entity_count"
                            @input="handleInput"
                            validation="required"
                            class="flex-1"
                            label="Min No. of Entities"
                            placeholder="Min No. of Entities"
                            type="number"
                            :element-class="
                                (context, classes) => ['flex-1 min-w-full'].concat(classes)
                            "
                            :min="minimumEntityCount"
                            :max="maximumEntityCount"
                            :disabled="isViewMode"
                        />
                        <FormulateInput
                            v-model="descriptionData.maximum_entity_count"
                            @input="handleMaxEntityCountInput({ $event, el: descriptionData })"
                            validation="required"
                            class="flex-1"
                            label="Max No. of Entities"
                            placeholder="Max No. of Entities"
                            type="number"
                            :element-class="
                                (context, classes) => ['flex-1 min-w-full'].concat(classes)
                            "
                            :min="minimumEntityCount"
                            :max="maximumEntityCount"
                            :disabled="isViewMode"
                        />
                    </div>

                    <!-- Dynamic entity counts -->
                    <div
                        v-for="el in entityNumbers"
                        :key="el.id"
                        class="description__numbers-row flex gap-4 justify-between"
                    >
                        <FormulateInput
                            v-model="el.minimum_entity_count"
                            @input="handleInput"
                            class="flex-1"
                            :label="`Min No. of ${el.name}`"
                            :placeholder="`Min No. of ${el.name}`"
                            type="number"
                            :element-class="
                                (context, classes) => ['flex-1 min-w-full'].concat(classes)
                            "
                            :min="minimumEntityCount"
                            :max="maximumEntityCount"
                            :disabled="isViewMode"
                        />
                        <FormulateInput
                            v-model="el.maximum_entity_count"
                            @input="handleMaxEntityCountInput({ $event, el })"
                            class="flex-1"
                            :label="`Max No. of ${el.name}`"
                            :placeholder="`Max No. of ${el.name}`"
                            type="number"
                            :element-class="
                                (context, classes) => ['flex-1 min-w-full'].concat(classes)
                            "
                            :min="minimumEntityCount"
                            :max="maximumEntityCount"
                            :disabled="isViewMode"
                        />
                    </div>
                </div>
            </UISection>

            <div class="flex justify-end max-w-full gap-4" v-allow="'package.edit'">
                <Button
                    type="primary"
                    text="Save"
                    :disabled="isViewMode || hasErrors || !isDirty"
                    :style="{
                        opacity: !isViewMode ? 1: 0.5,
                        cursor: !isViewMode ? 'pointer' : 'not-allowed'
                    }"
                    @click="updatePackage"
                />
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import axios from "@/axios";
import loader from "@/components/loader";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Button from "@/components/button";
import { checkPermission } from "@shared/utils/functions";
import {
    ENTITY_TYPES_DROPDOWN_CONFIG,
    DEFAULT_PACKAGE_ENTITY_TYPE,
    MINIMUM_ENTITY_COUNT,
    MAXIMUM_ENTITY_COUNT,
} from "@shared/utils/constants";
import { checkDefaultEntityType, reorderPackageEntityTypes } from "@shared/utils/functions";

export default {
    name: "description",
    components: {
        loader,
        Button,
    },
    props: [],

    data() {
        return {
            ENTITY_TYPES_DROPDOWN_CONFIG,
            editMode: true,
            descriptionData: null,
            isLoading: false,
            editTextArea: true,
            isTextAreaClicked: false,
            isInputClicked: false,
            isEntityTypesLoading: false,
            isValidationError: false,
        };
    },

    async mounted() {
        await this.fetchScreeningData();
    },

    computed: {
        ...mapGetters(["getPackage"]),
        ...mapState({
            entityTypeOptions: "entityTypeList",
            isDirty: state => state.PackageStore.isDirty,
        }),

        minimumEntityCount() {
            return MINIMUM_ENTITY_COUNT
        },

        maximumEntityCount() {
            return MAXIMUM_ENTITY_COUNT
        },

        isViewMode() {
            return this.$route.params.action === "view";
        },
        // consider fixed values:
        computedEntityTypeOptions() {
            return this.entityTypeOptions
                .map((el) => {
                    const isPreselected = !!checkDefaultEntityType(el);
                    return {
                        ...el,
                        $isDisabled: isPreselected,
                        preselected: isPreselected,
                        minimum_entity_count: MINIMUM_ENTITY_COUNT,
                        maximum_entity_count: 1,
                    }
                });
        },
        entityNumbers() {
            return this.descriptionData.entity_types?.filter((el) => !el.default) || [];
        },
    },
    methods: {
        ...mapActions([
            "fetchPackageData",
            "fetchEntityTypeList",
            "handlePackageDataChange"
        ]),
        ...mapMutations(["SET_PACKAGE"]),

        checkPermission,

        handleInput() {
            this.handlePackageDataChange(this.descriptionData)
        }, 

        handleMaxEntityCountInput({ el }) {
            el.minimum_entity_count = 1;
            this.handleInput();
        }, 

        handleClick() {
            if (this.$route.params.action !== 'view') {
                this.editMode = true;
            }
        },
        handleClickTextarea(){
            if (this.$route.params.action !== 'view') {
                this.editTextArea = !this.editTextArea;
            }
        },
        disableEditMode(){
            if(!this.isTextAreaClicked)
                this.editTextArea = false;
            this.isTextAreaClicked = false
        },
        disableInputEditMode(){
            if(!this.isInputClicked)
                this.editMode = false;
            this.isInputClicked = false
        },
        focusTextarea() {
            this.isTextAreaClicked = true;
        },
        focusInput(val) {
            this.isInputClicked= val;
        },
        async fetchScreeningData() {
            this.isLoading = true;
            await this.fetchPackageData(this.$route.params.packageName)
            this.descriptionData = this.getPackage

            this.handlePackageEntityTypes();
            this.setDefaultEntityType();
            this.isLoading = false;
        },

        async updatePackage() {
            if (this.isViewMode) return;

            this.validateEntities();

            if (!this.isValidationError) {
                const payload = {
                    package_name: this.descriptionData?.package_name?.trimEnd(),
                    package_description: this.descriptionData.package_description,
                    minimum_entity_count: +this.descriptionData.minimum_entity_count,
                    maximum_entity_count: +this.descriptionData.maximum_entity_count,
                    entity_types: this.descriptionData.entity_types.map((el) => ({
                        entity_type_id: el.id,
                        minimum_entity_count: +el.minimum_entity_count,
                        maximum_entity_count: +el.maximum_entity_count,
                    })),
                };

                try {
                    await axios.put(`/screening-package/${this.$route.params.packageName}`, payload);
                    this.$toast.success("Package description updated.");
                } catch (error) {
                    this.$toast.warning(error.response.data.detail || "Could not update package.");
                }
            }
            
            await this.fetchScreeningData();
        },
        hideEditMode(){
            this.editMode = false;
        },
        removeEditableMode() {
            if(this.editTextArea)  
                this.editTextArea = false;
        },
        removeEditablePackName(val) {
            this.editMode = val;
        },
        handlePackageEntityTypes() {
            const list = reorderPackageEntityTypes(this.descriptionData.entity_types, this.entityTypeOptions);
            const defaultType = list.find((el) => el.default);
            if (defaultType) defaultType.$isDisabled = true;
            this.descriptionData.entity_types = list;
        },
        setDefaultEntityType() {
            if (!this.descriptionData?.entity_types?.length) {
                const defaultEntityType = this.entityTypeOptions.find(el => el.name === DEFAULT_PACKAGE_ENTITY_TYPE.value)  ?? {}

                this.descriptionData.entity_types = [{
                    ...defaultEntityType,
                    $isDisabled: true,
                    preselected: true,
                    default: true,
                    minimum_entity_count: MINIMUM_ENTITY_COUNT,
                    maximum_entity_count: 1,
                }];
            }
        },

        validateEntities() {
            if (!this.descriptionData.maximum_entity_count) {
                this.isValidationError = true;
                return this.$toast.error("Total maximum entities in the package should be defined");
            }

            if (!this.descriptionData.minimum_entity_count) {
                this.isValidationError = true;
                return this.$toast.error("Total minimum entities in the package should be defined");
            }

            if (+this.descriptionData.minimum_entity_count > +this.descriptionData.maximum_entity_count) {
                this.isValidationError = true;
                return this.$toast.error("Total minimum entities should not be greater than maximum entities");
            }

            for (const entity of this.entityNumbers) {
                const maxEntityCount = +entity.maximum_entity_count;
                const minEntityCount = +entity.minimum_entity_count;

                if (minEntityCount > maxEntityCount) {
                    this.isValidationError = true;
                    return this.$toast.error(`${entity.name} minimum entities should not be greater than maximum entities`);
                }

                if (maxEntityCount > +this.descriptionData.maximum_entity_count) {
                    this.isValidationError = true;
                    return this.$toast.error(`Max ${entity.name} entities cannot be greater than total entities in the package`);
                }
            }

            this.isValidationError = false;
        },
    },
};
</script>

<style>

    /* .packageNameInput,.entityTypeInput{
        width:550px;
    } */

    .packageNameEditable{
        width:520px;
    }

    .descriptionInput{
        resize: none;
        border: 1px solid #e0dfdf !important;
    }

    .editIcon{
        font-size: 1.3rem;
    }

    .viewDescText{
        border: 1px solid #e0dfdf !important;
        background: none !important;
    }
</style>
